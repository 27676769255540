/*#region Navbar */

.primary-header {
  padding: var(--size-400) 0;
  background-color: var(--clr-neutral-white);
}

.nav {
  --max-width: 1700px;
  --container-padding: 2rem;

  width: min(var(--max-width), 100% - (var(--container-padding) * 2));
  margin-inline: auto;
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
}

.nav-logo {
  margin: auto 0;
  max-width: 5rem;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-mobile img {
  width: 3rem;
  cursor: pointer;
}

@media (min-width: 30em) {
  .nav-mobile {
    display: none !important;
  }

  .nav-extended-links {
    display: block !important;
  }
}

@media (max-width: 30em) {
  .nav-extended-links {
    display: none !important;
  }

  .nav-mobile {
    display: block !important;
  }
}

.nav-mobile-links {
  position: absolute;
  width: 100%;
  z-index: 102;
  display: grid;
  padding-top: 1.5rem;
  gap: 0.6rem;
  text-align: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  background-color: var(--clr-neutral-white);
}

.nav-link,
.dropdown-menu-header {
  font-weight: var(--fw-bold);
  cursor: pointer;
  text-decoration: none;
  color: var(--clr-neutral-black);
  font-size: var(--fs-625);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding: 0.5rem 0 0.5rem 2rem;
}

.dropdown-menu-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 0.2rem;
}

.dropdown-menu-header span {
  text-align: start;
}

.dropdown-menu-header img {
  justify-self: flex-end;
  margin-right: 2rem;
  height: 1.2rem;
  width: 1.6rem;
}

.dropdown-menu-items {
  display: grid;
  padding-top: 0.5rem;
  gap: 1rem;
  margin: 0 auto 1rem auto;
}

.dropdown-menu-item {
  text-decoration: none;
  font-size: var(--fs-600);
  color: var(--clr-neutral-black);
}

.nav-link {
  text-align: left;
}

/*#endregion*/

/*#region Hero */

.hr-group {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
}

.hr-img {
  width: 100%;
}

.hr-img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: hsla(0, 0%, 28%, 0.3);
  z-index: 99;
}

.hr-header-overlay {
  position: absolute;
  width: 100%;
  height: 60%;
  margin: auto;
  z-index: 100;
  text-align: center;
  color: var(--clr-neutral-white);
  text-shadow: 0px 4px 4px var(--clr-neutral-black-bs);
  display: flex;
  flex-direction: column;
  gap: 0;
}

.hr-header {
  font-size: var(--fs-850);
  font-weight: var(--fw-bold);
  line-height: 4rem;
  margin-bottom: 0.6rem;
}

.hr-sub-header {
  font-size: var(--fs-600);
  font-weight: var(--fw-bold);
  width: 80%;
  margin: auto;
}

/*#endregion Hero */

/*#region About */

.ab-wrapper {
  display: grid;
  gap: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 4rem;
}

.ab-wrapper > .btn {
  width: 70%;
  margin: auto;
  margin-top: 2rem;
}

/*#endregion About */

/*#region Services */

#services {
  background-color: var(--clr-neutral-white-2);
}

.fs-service-text {
  font-size: var(--fs-600);
  color: var(--clr-gray-text);
  text-align: center;
  font-weight: var(--fw-semi-bold);
  margin-top: 1rem;
}

.ser-card-list {
  display: grid;
  gap: 2.5rem;
  width: 96%;
  margin: auto;
  margin-top: 2rem;
}

.ser-card {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 2rem 0.3rem;
  background-color: var(--clr-neutral-white);
  box-shadow: 0 1.125em 1em -1em var(--clr-neutral-black-bs);
  height: 12rem;
  width: 100%;
}

.ser-card-header {
  font-size: var(--fs-650);
}

.ser-card > .btn {
  width: 70%;
  margin: auto;
  border-radius: 0.5rem;
  font-size: var(--fs-300);
}

/*#endregion Services */

/*#region ReminderNav */

#reminder {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.re-card-list {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.re-card {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.re-card-img {
  width: 3rem;
  margin: auto;
}

.re-card-header {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
  text-align: center;
}

.re-card-info p {
  font-weight: var(--fw-regular);
  color: var(--clr-gray-text);
  font-size: var(--fs-400) !important;
  text-align: center;
  margin: auto;
}

.re-card-link {
  text-decoration: none;
  color: var(--clr-accent-700);
  font-weight: var(--fw-regular);
  text-align: center;
  font-size: var(--fs-600);
  margin-top: 0.8rem;
  cursor: pointer;
}

/*#endregion ReminderNav */

/*#region Footer */

.primary-footer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3em;
  grid-template-columns: 1fr;
  background-color: var(--clr-gray-text);
  height: 18rem;
  padding: 3rem 2rem;
}

@media (max-width: 50em) {
  .primary-footer-wrapper > * {
    margin-inline: auto;
  }
}

.primary-footer-links {
  display: inline-flex;
  justify-content: center;
  gap: clamp(var(--size-200), 30vw, var(--size-900));
  color: var(--clr-neutral-white);
}

.primary-footer-nav {
  display: flex;
  gap: 3rem;
  margin: 0 auto;
  align-items: center;
}

.primary-footer-nav a {
  color: var(--clr-neutral-white);
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  text-align: left;
  text-align: center;
}

.footer-logo-links img {
  height: var(--size-700);
}

/*#endregion*/

/*#region Praxen */

#praxen {
  background-color: var(--clr-neutral-white-2);
}

.praxen-header {
  font-size: var(--fs-700);
  font-weight: var(--fw-regular);
  color: var(--clr-gray-text);
  margin-bottom: 2rem;
}

.praxis-card-list {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: 2rem;
}

.praxis-card {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 2rem 0.6rem;
  background-color: var(--clr-neutral-white);
  box-shadow: 0 1.125em 1em -1em var(--clr-neutral-black-bs);
}

.praxis-card-header {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
}

.praxis-card-subheader {
  font-size: var(--fs-500);
  color: var(--clr-accent-700);
  font-weight: var(--fw-regular);
}

.praxis-card > .btn {
  width: 50%;
  margin: 1rem auto 0 auto;
  font-size: var(--fs-400);
}

/*#endregion Praxen */

/*#region About */

.ab-img {
  border-radius: 0.5rem;
}

.ab-header {
  font-size: var(--fs-700);
  font-weight: var(--fw-bold);
  margin-bottom: 2rem;
  text-align: center;
}

.about-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.about-text-header {
  text-align: left;
  font-size: var(--fs-650);
  font-weight: var(--fw-regular);
  color: var(--clr-gray-text);
}

.about-text {
  text-align: justify;
  hyphens: auto;
  word-break: normal;
  line-height: 2;
  font-size: var(--fs-500);
  margin: auto;
  max-width: 40ch;
}

/*#endregion About */

/*#region PraxisPage */

.praxis-element-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-accent-800);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.current-officeTime-header {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  margin-bottom: 0.4rem;
}

.current-officeTime-header img {
  width: 2rem;
  height: 2rem;
  margin: auto;
}

.current-officeTime-header span,
.current-officeTime-text {
  color: var(--clr-neutral-white);
  font-size: var(--fs-500);
  font-weight: var(--fw-bold);
}

.current-officeTime-text {
  max-width: 36ch;
}

.praxis-element-header {
  color: var(--clr-neutral-white);
  font-weight: var(--fw-bold);
  font-size: var(--fs-800);
  margin-top: 1rem;
}

.praxis-element-underline {
  width: 86%;
  height: 0.2rem;
  background-color: var(--clr-neutral-white);
  border-radius: 3rem;
}

.praxis-element-doctor {
  color: var(--clr-neutral-white);
  font-weight: var(--fw-bold);
  font-size: var(--fs-625);
  margin-top: 1rem;
}

.praxis-element-title {
  color: var(--clr-neutral-white);
  font-weight: var(--fw-regular);
  font-size: var(--fs-300);
  width: 36ch;
  margin-top: 0.8rem;
}

.praxis-element-heading > .btn {
  font-size: var(--fs-400);
  margin-top: 2rem;
}

.praxis-element-welcome-text {
  text-align: justify;
  margin: auto;
  max-width: 36ch;
}

.praxis-element-service-wrapper {
  margin-top: 2rem;
}

.praxis-element-service-header {
  font-size: var(--fs-700);
  font-weight: var(--fw-bold);
  color: var(--clr-gray-text);
}

.praxis-element-service-list {
  margin-top: 2rem;
  display: grid;
  width: 100%;
  gap: 0.7rem;
}

.praxis-element-service-list-item {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  align-items: flex-start;
}

.praxis-element-service-list-divider {
  background-color: var(--clr-accent-700);
  height: 0.7rem;
  width: 0.7rem;
  border-radius: 10vh;
}

.praxis-element-service-list-item-text {
  font-size: var(--fs-500);
  font-weight: var(--fw-semi-bold);
  hyphens: auto;
  position: relative;
  top: -4px;
}

.praxis-element-service-note,
.praxis-element-service-extended-note {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 1rem;
  box-shadow: 0 1.125em 1em -1em var(--clr-neutral-black-bs);
  border-radius: 1rem;
  background-color: var(--clr-neutral-white-2);
  padding: 1rem 2rem;
  margin-top: 3rem;
  line-height: 2;
}

.praxis-element-service-note img,
.praxis-element-service-extended-note img {
  height: 2.5rem;
  width: 2.5rem;
  margin: auto;
}

.praxis-element-service-note span {
  text-align: left;
  font-size: var(--fs-400);
  max-width: 34ch;
  hyphens: auto;
}

.praxis-element-service-extended-note p {
  text-align: left;
  font-size: var(--fs-400);
  max-width: 34ch;
  hyphens: auto;
  margin: auto;
}

.praxis-element-praxis-gallery {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.praxis-element-praxis-gallery-header {
  font-size: var(--fs-700);
  font-weight: var(--fw-bold);
  color: var(--clr-gray-text);
  text-align: center;
}

.praxis-element-praxis-gallery-wrapper {
}

#kontakt-sprechzeiten {
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#kontakt-sprechzeiten {
  padding-top: 0;
  padding-bottom: 0;
}
.praxis-element-contact-wrapper {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.praxis-element-contact-info-header {
  color: var(--clr-neutral-white);
  font-size: var(--fs-625);
  font-weight: var(--fw-bold);
  text-align: center;
}

.praxis-element-contact-info-group-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.praxis-element-contact-info-group-list-item {
  display: grid;
  gap: 0.8rem;
}

.praxis-element-contact-wrapper {
  background-color: var(--clr-accent-800);
  display: grid;
  grid-template-columns: 1fr;
}

.praxis-element-contact-info {
  display: grid;
  gap: 1.4rem;
  margin: auto;
}

.praxis-element-contact-info-item {
  display: flex;
  gap: 1.5rem;
  justify-content: flex-start;
  align-items: center;
}

.praxis-element-contact-info-card {
  height: 4rem;
  width: 4rem;
  background-color: var(--clr-neutral-white);
  box-shadow: 0 1.125em 1em -1em var(--clr-neutral-black-bs);
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.praxis-element-contact-info-card img {
  height: 3rem;
  width: 3rem;
}

.praxis-element-contact-info-item-text {
  color: var(--clr-neutral-white);
  font-size: var(--fs-500);
  text-align: left;
}

.praxis-element-contact-ot {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.praxis-element-contact-ot-header {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  gap: 0.2rem;
}

.praxis-element-contact-ot-header img {
  height: 1.2rem;
  width: 1.2rem;
}

.praxis-element-contact-ot-header span {
  color: var(--clr-neutral-white);
  font-size: var(--fs-625);
  font-weight: var(--fw-bold);
  text-align: left;
}

.praxis-element-contact-ot-times {
  display: grid;
  gap: 1rem;
}

.praxis-element-contact-ot-extended-times-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.praxis-element-contact-ot-extended-times {
  display: grid;
  gap: 1rem;
}

.praxis-element-contact-ot-extended-times-header {
  font-size: var(--fs-625);
  color: var(--clr-neutral-white);
}

.praxis-element-contact-ot-extended-times .praxis-element-contact-ot-times {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.praxis-element-contact-ot-time-element {
  color: var(--clr-neutral-white);
  font-size: var(--fs-500);
  text-align: left;
}

.praxis-element-contact-ot-table {
  border-collapse: collapse;
}

.praxis-element-contact-ot-table-header {
  background-color: var(--clr-neutral-white);
}

.praxis-element-contact-ot-table-header-value,
.praxis-element-contact-ot-table-td {
  padding: 0.5rem;
  border: 1px solid var(--clr-neutral-black);
}

.praxis-element-contact-ot-table-header-value {
  font-size: var(--fs-625);
}

.praxis-element-contact-ot-table-tr {
  background-color: var(--clr-table-gray-1);
}

.praxis-element-contact-ot-table-tr:nth-child(even) {
  background-color: var(--clr-table-gray-2);
}

.praxis-element-contact-ot-table-tr p:nth-child(even) {
  font-size: 0.7rem;
}

.praxis-element-contact-ot-table-td {
  font-size: var(--fs-550);
  text-align: center;
  max-width: 100%;
  hyphens: auto;
}

@media (max-width: 60rem) {
  .praxis-element-contact-ot-times {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0.75rem;
  }

  .praxis-element-contact-wrapper {
    display: flex !important;
    flex-direction: column;
    gap: 4rem;
    justify-content: center;
    align-items: center;
  }

  .praxis-element-contact-ot {
    margin: auto;
  }
}

.praxis-element-contact-ot-times-element:last-child {
  border-bottom: none;
  padding-bottom: 0rem;
}

.praxis-element-contact-ot-times-element {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid var(--clr-neutral-white);
  padding-bottom: 1rem;
  color: var(--clr-neutral-white);
}

.praxis-element-contact-ot-times-element-header {
  font-size: var(--fs-550);
}

.praxis-element-contact-ot-times-element-ot-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.praxis-element-contact-ot-times-element-ot {
}

.praxis-element-contact-ot-times-element-ot p {
  font-size: var(--fs-300) !important;
}

.praxis-element-contact-ot-times-element-ot p:first-child {
  font-size: var(--fs-550) !important;
}

/*#endregion PraxisPage */

/*#region PraxisPageGallery */

.card-slider {
  position: relative;
  padding-bottom: 3rem;
}

.card-link-list {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
}

.card img {
  padding: 0.5rem auto;
  border-radius: 1rem;
}

.card-slider-pagination-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 98;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.card-slider-pagination {
  display: flex;
  gap: 0.6rem;
  justify-content: center;
}

.card-slider-pagination-chevron {
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
}

.card-slider-pagination-item {
  width: 1rem;
  height: 1rem;
  border-radius: 10rem;
  background-color: var(--clr-gray-text);
  cursor: pointer;
}

.card-slider-pagination-item-active {
  background-color: var(--clr-accent-700);
}

/*#endregion PraxisPageGallery */

/*#region Extended Service Card */

.praxis-element-extended-service-list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 2rem;
}

.praxis-extended-service-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
}

.praxis-extended-service-card-header-wrapper {
  display: grid;
  grid-template-columns: 0.9fr 0.1fr;
  gap: 1rem;
  justify-content: center;
  box-shadow: 0 1.125em 1em -1em var(--clr-neutral-black-bs);
  background-color: var(--clr-accent-700);
  align-items: center;
  padding: 1rem 1.5rem;
}

.praxis-extended-service-card-header {
  color: var(--clr-neutral-white);
  font-size: var(--fs-625);
  font-weight: var(--fw-bold);
  max-width: 20ch;
  hyphens: auto;
}

.praxis-extended-service-card-control {
  height: 0.6rem;
  width: 0.8rem;
}

.praxis-extended-service-card-dropdown {
  height: 0px;
  display: none;
}

.praxis-extended-service-card-dropdown[data-type="open"] {
  background-color: var(--clr-neutral-white-2);
  box-shadow: 0 1.125em 1em -1em var(--clr-neutral-black-bs);
  text-align: center;
  padding: 1rem 1.2rem 1.8rem 1.2rem;
  gap: 1.2em;
  display: grid !important;
  height: 100%;
  display: block;
}

.praxis-extended-service-card-dropdown div {
  max-width: 40ch;
  line-height: 2.2;
  font-size: var(--fs-400);
}

/*#endregion Extended Service Card */

/*#region Vertical Line Animation */

.vl-adjuster {
  bottom: 0;
  position: absolute;
  margin: 0 auto 1.4rem auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

.vertical-line {
  height: 1px;
  width: 2.2rem;
  transform: rotate(90deg);
  overflow: hidden;
  z-index: 100;
}

.vertical-line::before,
.vertical-line::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
}

.vertical-line::before {
  background-color: transparent;
}

.vertical-line::after {
  background-color: var(--clr-neutral-white);
  animation: move 4s infinite;
}

@keyframes move {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(140%, 0, 0);
  }
}

/*#endregion Vertical Line Animation */

/*#region Utility classes */

.document-hidden {
  display: none;
  visibility: hidden;
}

.paragraph-wrapper {
  text-align: center;
  display: block;
}

.paragraph-wrapper p {
  text-align: justify;
  hyphens: auto;
}

.paragraph-wrapper a {
  text-decoration: none;
  color: var(--clr-link);
}

.even-areas {
  display: grid;
}

@media (min-width: 54em) {
  .even-areas {
    grid-template-columns: 40% 60%;
  }
}

@media (max-width: 54em) {
  .even-areas {
    grid-template-areas:
      "area-img"
      "area-content";
  }

  .hr-wrapper {
    grid-area: area-content;
  }

  .hr-img-wrapper {
    grid-area: area-img;
  }
}

.even-areas[data-type="gap"] {
  gap: var(--size-500);
}

.even-columns {
  display: grid;
  gap: 1rem;
}

@media (min-width: 50em) {
  .even-columns {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}

.text-center {
  text-align: center;
}

.text-center p {
  margin-inline: auto;
}

.fw-bold {
  font-weight: var(--fw-bold);
}
.fw-semi-bold {
  font-weight: var(--fw-semi-bold);
}
.fw-regular {
  font-weight: var(--fw-regular);
}

.fs-primary-heading {
  padding-top: var(--size-500);
  font-size: var(--fs-primary-heading);
  line-height: 1.1;
  margin-bottom: var(--size-500);
}

.padding-block-900 {
  padding: var(--size-900) 0;
}

.padding-block-700 {
  padding: var(--size-700) 0;
}

.padding-block-500 {
  padding: var(--size-500) 0;
}

.margin-block-500 {
  margin: var(--size-500) 0;
}

.padding-inline {
  padding-right: var(--size-500);
  padding-left: var(--size-500);
}

.horizontal-line {
  border-bottom: 1px solid var(--clr-neutral-white);
  width: 100%;
}

.misc-heading {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
  color: var(--clr-gray-accent-100);
}

.misc-paragraph {
  font-size: var(--fs-400);
  hyphens: auto;
  line-height: 2;
  text-align: left;
  margin: auto;
  padding: 0 2rem;
}

.misc-paragraph p {
  margin: 0;
  max-width: 100%;
  line-height: 2;
}

.misc-paragraph h2 {
  font-size: var(--fs-625);
}

.misc-paragraph h3 {
  font-size: var(--fs-500);
}

.misc-paragraph li {
  margin-left: 3rem;
}

/*#endregion*/

/*#region Media Queries */

/* Large <1248px */
@media (min-width: 114em) {
  .hr-header {
    font-size: 9rem;
  }
}

@media (min-width: 87.5em) {
  .nav {
    grid-template-columns: 3fr 2fr;
  }

  .praxis-element-contact-wrapper {
    width: 70% !important;
  }
}

/* Computer 1055px*/
@media (min-width: 30em) {
  /*#region Nav */

  .primary-header {
    z-index: 200;
    width: 100%;
    position: absolute;
  }

  .nav {
    grid-template-columns: 1fr 2fr;
  }

  .btn-icon {
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  .btn-icon-text {
    font-size: var(--fs-625);
  }

  .nav-extended-links {
    display: flex !important;
    flex-direction: row !important;
    gap: 3rem;
    justify-content: flex-start;
  }

  .dropdown-menu-items {
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;
    padding-left: 2rem;
  }

  .dropdown-menu-item {
    text-decoration: none;
    font-size: var(--fs-600);
    color: var(--clr-neutral-black);
  }

  .nav-modal {
    position: absolute;
    display: grid;
    gap: 1rem;
    background-color: var(--clr-neutral-white);
    box-shadow: 0 0.2em 1em 0 rgba(0, 0, 0, 0.1);
    border-radius: 0 0 1.875em 1.875em;
    padding: 1em 1.5em;
    min-width: 10rem;
    margin: 0.6rem auto 0 auto;
  }

  .nav-modal[data-type="hidden"] {
    display: none;
  }

  .nav-modal-link {
    text-decoration: none;
    color: var(--clr-black);
    font-weight: var(--fw-bold);
  }

  .nav-modal-link:hover {
    color: var(--clr-blue-accent-200);
  }

  .nav-link-dropdown {
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .nav-link-dropdown .btn-icon {
    gap: 1rem;
  }

  .nav-link-dropdown .btn-icon img {
    max-width: 0.8rem;
  }

  .nav-link-dropdown .btn-icon-text a {
    color: var(--clr-neutral-black);
  }

  .btn-icon-hl {
    height: 2px;
    width: 100%;
    background-color: var(--clr-neutral-white);
    position: absolute;
    z-index: 800;
    bottom: 0;
    left: 0;
    margin-top: -0.5rem;
  }

  .btn-icon-hl::before,
  .btn-icon-hl::after {
    content: "";
    animation: hoverGrow 0.15s reverse;
  }

  .nav-link-dropdown:hover .btn-icon-hl {
    background-color: var(--clr-accent-700);
    animation: hoverGrow 0.15s linear;
  }

  @keyframes hoverGrow {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }

  /*#endregion Nav */

  /*#region Hero */

  #home {
    height: 100vh;
  }

  .hr-group {
    height: 100vh;
  }

  #home > .hr-img-overlay,
  .hr-img {
    height: 100%;
  }

  .hr-img {
    position: static;
    z-index: 1;
  }

  .hr-header-overlay {
    height: 100%;
    padding: 8% 0;
    text-align: left;
    background-image: url("../img/desktop_new.webp");
    /*background-image: url("../img/PraxisAllgemeinMvzMediFalkenberg1-768x512.webp"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }

  .hr-header-wrapper {
    display: grid;
    grid-template-columns: 1.5fr 0.5fr;
    justify-content: center;
  }

  .hr-header {
    width: 80%;
    line-height: 1.4;
    margin: auto;
    margin-left: 14%;
  }

  .hr-sub-header {
    font-size: 2rem;
    margin-left: 14%;
  }

  .vl-adjuster {
    margin-bottom: 3.5rem;
  }

  .vertical-line {
    width: 3rem;
  }

  /*#endregion Hero */

  /*#region About */

  .ab-text p {
    max-width: 60ch;
  }

  /*#endregion About */

  /*#region Service */

  .fs-service-text {
    font-size: var(--fs-625);
  }

  .ser-card-list {
    width: 100%;
    /*grid-template-columns: repeat(2, auto);*/
    grid-template-areas: "a b" "c d" "e e";
    margin: 3rem auto 3rem auto;
    gap: 3rem;
    justify-content: center;
    align-items: center;
  }

  .ser-card {
    width: 30rem;
  }

  .ser-card[data-pos="lower"] {
    grid-area: e;
    margin: auto;
  }

  /*#endregion Service */

  /*#region Reminder */

  .re-card-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 64rem;
    margin: auto;
  }

  .re-card {
    width: 30rem;
    margin: auto;
  }

  /*#endregion Reminder */

  /*#region MVZ */

  #about .even-areas {
    grid-template-columns: 50% 50%;
    justify-content: center;
    align-items: flex-start;
    margin-top: 3rem;
  }

  .about-text-wrapper {
    margin: 0;
    gap: 3rem;
    padding-left: 2rem;
  }

  .about-text-header {
    font-size: var(--fs-800);
  }

  .about-text {
    margin: 0 auto 0 0;
    max-width: 80ch;
    font-size: var(--fs-600);
  }

  .ab-img {
    height: 100%;
  }

  /*#endregion MVZ */

  /*#region Praxen */

  #praxen {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .praxen-header {
    font-size: var(--fs-800);
  }

  .praxis-card-list {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 3rem auto 3rem auto;
    gap: 3rem;
    justify-content: center;
    align-items: center;
  }

  .praxis-card {
    width: 30rem;
  }

  .praxis-card-list[data-type="innereMed"] {
    grid-template-columns: 1fr 1fr 1fr;
  }

  /*#endregion Praxen */

  /*#region Praxisseite */

  #praxis-element {
    padding-top: 6rem;
  }

  .praxis-element-underline {
    width: 40%;
  }

  .container-gray {
    background-color: var(--clr-neutral-white-2);
  }

  #praxis-element .padding-block-700 {
    background-color: var(--clr-neutral-white);
  }

  #praxis-element .padding-inline {
    padding-left: var(--size-800);
    padding-right: var(--size-800);
  }

  .praxis-element-welcome-text {
    max-width: 90ch;
    font-size: var(--fs-600);
  }

  .praxis-element-service-wrapper {
    padding-left: 4rem;
    padding-right: 4rem;
    margin-top: 5rem;
    margin: 5rem auto 0 auto;
  }

  .praxis-element-service-header {
    font-size: var(--fs-700);
  }

  .praxis-element-service-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }

  .praxis-element-service-list-divider {
    height: 1rem;
    width: 1rem;
  }

  .praxis-element-service-list-item-text {
    font-size: var(--fs-625);
  }

  .praxis-element-service-note,
  .praxis-element-service-extended-note {
    margin-top: 5rem;
    width: auto;
    max-width: 50ch;
    justify-content: center;
    align-items: center;
  }

  .praxis-element-service-extended-note p {
    margin: auto;
    text-align: left;
    max-width: 100%;
  }

  .praxis-element-service-note span {
    text-align: center;
    margin: auto;
    font-size: var(--fs-600);
  }

  .praxis-element-praxis-gallery-header {
    font-size: var(--fs-700);
  }

  .praxis-element-contact-info-group-list {
    grid-template-columns: 1fr 1fr;
  }

  .card-link-list {
    justify-content: center;
  }

  .card {
    height: 100%;
  }

  .card img {
    height: 26rem;
    width: 40rem;
  }

  .praxis-extended-service-card-header-wrapper {
    grid-template-columns: 0.95fr 0.05fr;
  }

  .praxis-extended-service-card-header {
    max-width: 90%;
  }

  .praxis-extended-service-card-dropdown {
  }

  .praxis-extended-service-card-dropdown p {
    max-width: 110ch;
    text-align: left;
    margin: auto;
    width: 96%;
  }

  #kontakt-sprechzeiten {
    background-color: var(--clr-accent-800);
  }

  .praxis-element-contact-wrapper {
    width: 90% !important;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    padding-top: 4rem;
    padding-bottom: 4rem;
    align-items: flex-start;
  }

  .praxis-element-contact-ot {
    margin-top: 0;
    gap: 1.2rem;
  }

  /*#endregion Praxisseite */

  /*#region Aktuelles */

  #news {
    padding-top: 8rem;
    padding-bottom: 8rem;
    text-align: center;
    font-size: var(--fs-850);
    font-weight: var(--fw-bold);
  }

  /*#endregion Aktuelles */

  /*#region Jobs */

  #jobs {
    padding-top: 8rem;
    padding-bottom: 8rem;
    text-align: center;
    font-size: var(--fs-850);
    font-weight: var(--fw-bold);
  }

  /*#endregion Jobs */

  /*#region Footer */

  .primary-footer-wrapper {
    display: grid;
    grid-template-areas: "copyright links";
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    height: 10rem;
  }

  .primary-footer-nav {
    display: flex;
    justify-content: space-around;
    gap: 4rem;
    grid-area: links;
  }

  .primary-footer-links {
    grid-area: copyright;
  }

  .primary-footer-nav > .footer-wrapper {
    display: flex;
    flex-direction: row;
    gap: 4rem;
  }

  /*#endregion Footer */

  /*#region Utillity Classes */

  .btn {
    width: 18rem !important;
  }

  .fs-primary-heading {
    font-size: var(--fs-750);
  }

  .padding-block-500,
  .padding-block-700 {
    width: 80%;
    margin: auto;
  }

  #impressum .misc-heading,
  #datenschutz .misc-heading {
    margin-top: 6rem;
  }

  /*#endregion Utillity Classes */
}

/* Laptops 864px-1024px */
@media (min-width: 50em) and (max-width: 66em) {
}

@media (min-width: 30em) and (max-width: 114em) {
  .hr-header {
    font-size: 7rem;
  }

  .hr-sub-header {
    font-size: 1.5rem;
  }
}

/* Tablet 480px-860px */
@media (min-width: 30em) and (max-width: 50em) {
}

/* Phone */

/*#endregion Media Queries*/
